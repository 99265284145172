// import uuidv1 from 'uuid/v1';
import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'AVaunt Consulting', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: 'IT / Audio Visual / Web Consultancy', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: 'AVaunt',
  name: 'Consulting',
  subtitle: 'IT / Audio Visual / Web',
  cta: 'Know more',
};

// ABOUT DATA
export const aboutData = {
  img: 'avaunt-logo-white.png',
  paragraphOne: 'AVaunt is a forward thinking IT Consulting company.',
  paragraphTwo:
    'We are an innovative and versatile consulting team with broad experience at delivering strategic business change and technology transformation. Our clients have included Barclays, RBS, ' +
    'Societe Generale and National Australia Bank. We are firm believers of aligning technology delivery to the business strategy and vision.',
  paragraphThree: ' ',
  resume: '', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    // id: uuidv1(),
    id: nanoid(),
    img: 'obs.jpg',
    title: 'IT & AV Consultancy',
    info:
      'AVaunt’s IT and AV consultancy team specialise in integrating business systems and services into existing or new IT infrastructure. We can provide the expertise to help design and deliver ' +
      'the transition from your current state to an integrated corporate services and IT platform. Covering Audio Visual, Security, facilities and other traditional non-IT based functions.',
    info2: '',
    url: 'https://www.linkedin.com/in/andrew-rankine-49057117/',
    repo: '', // if no repo, the button will not show up
  },
  {
    // id: uuidv1(),
    id: nanoid(),
    img: 'avweb.jpg',
    title: 'Web Development',
    info:
      'Having a website that you’re proud of and which accurately reflects your brand is one of the most important aspects of any business. Research has even suggested that having a website can ' +
      "increase turnover by around 45%.  I know searching and finding someone to build your own website can be a daunting task especially if you're not IT minded.",
    info2: "Well we're here to help...",
    url: 'https://www.avwebdesign.co.uk/',
    repo: '', // if no repo, the button will not show up
  },
  {
    // id: uuidv1(),
    id: nanoid(),
    img: 'velow.jpg',
    title: 'Velow.bike',
    info: 'AVaunt is the founder of Velow.bike',
    info2:
      'The core mission of Velow.bike is to help the environment by encouraging more people to cycle to work.',
    url: 'https://velow.bike/',
    repo: '', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: 'Get in contact',
  btn: 'email',
  email: 'info@avaunt.io',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      // id: uuidv1(),
      id: nanoid(),
      name: 'twitter',
      url: 'https://twitter.com/a_vaunt',
    },
    // {
    //   id: uuidv1(),
    //   name: 'codepen',
    //   url: '',
    // },
    // {
    //   // id: uuidv1(),
    //   id: nanoid(),
    //   name: 'linkedin',
    //   url: 'https://www.linkedin.com/in/andrew-rankine-49057117/',
    // },
    // {
    //   id: uuidv1(),
    //   name: 'github',
    //   url: '',
    // },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
